<template>
  <div class="body">
    <!-- banner区域 Start -->
    <div class="banner">
      <m-top-bar></m-top-bar>
    </div>
    <!-- banner区域 End -->

    <!-- switchBar区域 Start -->
    <div class="switchBar">
      <div class="switchBox">智能无线保管柜</div>
    </div>
    <div class="line"></div>
    <!-- switchBar区域 End -->

    <!-- container区域 Start -->
    <div class="container">
      <div class="containerBox">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;智能保管柜系统基于模块化设计，运用RFID射频技术、自动控制技术、工业网络技术、互联网技术，
        具有运行稳定、功能丰富，根据需求可灵活组合，智能化功能丰富，
        客户定制便利，便于扩展、单元自持等特点。基于超高频RFID扫描系统，物资识别率达到99.9%，扫描速度1～10S可调。<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;智能保管柜系统管理功能丰富，接口灵活，具有良好的扩展性，
        支持远程Web操作与基于物联网及云数据的管理。全面支持中心主站、云主站的构架，规模可随意配置，
        不受某个系统的物理容量限制。在无人仓建设中可便利的与其它仓储智能化系统构成一个整体。
        <img src="../../../../assets/mobile/img/solution/cabinetPic.png" alt="" class="pic">
      </div>
    </div>
    <!-- container区域 End -->

    <!-- baseBar区域 Start -->
    <m-base-bar></m-base-bar>
    <!-- baseBar区域 End -->
  </div>
</template>

<script>
import mTopBar from '@/components/mTopBar.vue'
import mBaseBar from '@/components/mBaseBar.vue'

export default {
  name: 'Support',
  components: {
    mTopBar,
    mBaseBar,
  }
}
</script>

<style scoped>
.body {
  background-color: #f7f7f7 !important;
}

/* banner区域 */
.banner {
  width: 100%;
  min-height: 2050px;
  background: url(../../../../assets/mobile/img/solution/banner.jpg) center no-repeat;
  background-size: 335%;
}

/* switchBar区域 */
.switchBar {
  padding-top: 50px;
  width: 100%;
  height: 300px;
  text-align: center;
  background-color: #ffffff;
}

.switchBar .switchBox {
  margin: 0 auto;
  text-align: center;
  width: 700px;
  height: 200px;
  font-size: 72px;
  line-height: 200px;
  color: #ffffff;
  border-radius: 30px;
  background-color: #c3a35d;
}

.line {
  margin: 0 auto;
  width: 90%;
  border-bottom: 1px solid #c3a35d;
}

/* container区域 */
.container {
  padding: 50px;
  width: 100%;
  min-height: 500px;
}

.container .containerBox {
  padding: 100px;
  width: 100%;
  min-height: 300px;
  font-size: 70px;
  text-align: justify;
  background-color: #ffffff;
}

.container .containerBox .pic {
  margin-top: 150px;
  width: 1600px;
}
</style>